<template>
  <a-col v-for="folder in archivedFolders" :key="folder"
    :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
    <div  class="folder">
      <img
        src="@/assets/icons/glocal-folder-icon.svg"
        alt="Folder Icon"
        class="folder__icon"
      />
      <div class="folder__content">
        <h1 class="folder__content--title">{{ folder.name }}</h1>
        <p class="folder__content--para">{{ subFolderCount(folder) > 1 ? `${subFolderCount(folder)} Sub Folders` : subFolderCount(folder) === 1 ? `${subFolderCount(folder)} Sub Folder` : `No Sub Folders`  }}</p>
      </div>
      <a-dropdown
        @click.stop
        :trigger="['click']"
        placement="bottomRight"
      >
        <a class="ant-dropdown-link folder__elipsess">
          <i class="icon icon-vertical_three-dots"></i>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item @click.stop="unarchiveFolder(folder)" key="3">
              <a>Unarchive Folder</a>
            </a-menu-item>
            <a-menu-item @click.stop="deleteArchiveFolder(folder)" key="4">
              <a class="remove-btn">
                Remove
              </a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </a-col>
</template>

<script>
import folderMixin from '../../mixins/folders.mixnin.js';

export default {
  data() {
    return {
      visibleQRCodeBar: false
    }
  },
  mixins: [folderMixin],
  computed: {
    archivedFolders() {
      return this.$store.getters['folders/getArchivedFolder'];
    }
  },
  methods: {
    setFolderUrl(folder) {
      // let tab = this.$route.params.class === 'personal' ? 'personal' : 'class';
      this.$router.push(`/workspaces/${this.folderType}/${folder.folder.id}`);
    },
    unarchiveFolder(folder) {
      this.emitter.emit('unarchive-folder', folder); 
    },
    deleteArchiveFolder(folder) {
      const folderToBeRemoved = {
        id: folder.id,
        isParentFolder: folder.parent,
        parentFolderId: folder.parent,
        isArchivedFolder: true
      }
      // this.createActivity(folder.id, 'folder_content', 'remove');
      this.emitter.emit('delete-archive-folder', folderToBeRemoved); 
    },
    showQRPupup() {
      this.visibleQRCodeBar = true;
    },
    subFolderCount(folder){
      /** 
       * recursive function to calculate the sub folders
      */
      if (folder.child.length === 0)
        return 0
      else{
        return folder.child.reduce((acc, child) => {
          return acc + (child ? 1 : 0) + this.subFolderCount(child); // Recursion happens here for each child
        }, 0);
      }
    }
  }
};
</script>

<style lang="scss">
.folder {
  display: flex;
  align-items: center;
  padding: 2rem 2rem !important;
  border: 1px solid $color-dark-grey-5;
  cursor: pointer;
  border-radius: 0.8rem;
  &__icon {
    width: 4rem;
    height: auto;
  }
  &__content {
    margin-left: 1.6rem;
    &--title {
      color: $color-black;
      font-size: 1.4rem;
      font-family: $font-primary-medium;
      line-height: 1.6rem;
      margin-bottom: 0.5rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    &--para {
      color: #585858;
      font-size: 1.2rem;
      font-family: $font-primary;
      line-height: 1.4rem;
      margin-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      width: fit-content;
    }
  }
  &__elipsess {
    margin-left: auto;
    .icon {
      color: $color-black;
      font-size: 1.8rem !important;
    }
  }
  
}
</style>
