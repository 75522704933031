<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    :header="null"
    width="50rem"
    class="add-new-tab-modal"
  >
    <div class="add-new-tab-modal-container">
      <div class="add-new-tab-modal-container__header">
        <h1>Add a New Folder Type</h1>
      </div>
      <div class="add-new-tab-modal-container__body">
        <input type="text" placeholder="Enter folder type name" />
        <!-- <p class="add-new-tab-modal-container__body--error-msg"></p> -->
        <a-button
          :loading="loader"
          class="add-new-tab-modal-container__body--btn"
        >
          Add
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      folderType: null,
      loader: false,
      options: [
        {
          label: "Private",
          value: "private",
        },
        {
          label: "Public",
          value: "public",
        },
        {
          label: "Organization",
          value: "organization",
        },
      ],
    };
  },
  emits: ["close-modal"],
  computed: {},
  methods: {
    closeModal() {
      this.loader = false;
      this.folderType = null;
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss">
.add-new-tab-modal {
  top: 15rem !important;
  .ant-modal-content {
    background-color: #f6f8ff;

    .ant-modal-close {
      margin: 1.9rem 2rem;
    }
    .ant-modal-body {
      padding: 0;
      .add-new-tab-modal-container {
        padding: 2.2rem 2.5rem;
        &__header {
          margin-bottom: 2.8rem;
          h1 {
            font-size: 2rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: normal;
            color: $color-black;
          }
        }
        &__body {
          input {
            width: 100% !important;
            height: 4rem !important;
            font-family: $font-primary;
            font-size: 1.6rem;
            color: $color-black;
            padding: 0 1.5rem;
            border: 1px solid #00000033;
            border-radius: 0.5rem !important;
            outline: none;
            &::placeholder {
              color: $color-black;
              opacity: 0.4;
            }
          }
          &--error-msg {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: red;
            margin: 0.8rem 0 0;
          }
          &--btn {
            margin-top: 3rem;
            outline: none;
            border: none;
            background-color: $color-primary;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            color: $color-white;
            height: 4rem;
            width: 10rem;
            display: block;
            margin-left: auto;
            border-radius: 5px !important;
            cursor: pointer;
            padding: 0 !important;
            .anticon {
              .anticon-spin {
                font-size: 2rem;
                color: $color-primary;
              }
            }
          }
        }
      }
    }
  }
}
</style>
