import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'


const wordLimitExtension = Extension.create({
    name: 'wordLimitExtension',

    addOptions(){
        return {
            maxWords:100
        }
    },

    addProseMirrorPlugins(){
        const maxWords = this.options.maxWords
        const editor = this.editor
        return[
            new Plugin({
                  
                appendTransaction: (transactions, oldState, newState) =>{
                    const maxWords = this.options.maxWords 
                    const currentWordCount = getWordCount(newState.doc.textContent)

                    if (currentWordCount >= maxWords) {
                        const tr = oldState.tr

                        // Ensure the selection is kept intact, and no further text can be added
                        tr.setSelection(oldState.selection)

                        // Cancel the insertion of new text by returning this transaction
                        tr.deleteSelection() // Ensure no new text is inserted
                        // return tr
                    }
                },
                props: {
                    handleKeyDown(view, event) {
                      const currentWordCount = getWordCount(view.state.doc.textContent)
          
                        if (event.key === 'Enter') {
                            // Block keypress events when max word count is reached
                            event.preventDefault()
                            return editor.commands.setHardBreak() // Indicates that the event has been handled
                        }
                      // If the word count exceeds the limit, prevent further typing
                      if (currentWordCount >= maxWords) {
                        if (event.key !== 'Backspace' && event.key !== 'Delete') {
                        // Block keypress events when max word count is reached
                        event.preventDefault()
                        return true // Indicates that the event has been handled
                        }
                      }
          
                      return false // Allow normal handling of keypress events
                    },
                    handlePaste(view, event) {
                        // Get the pasted content
                        const pastedText = event.clipboardData.getData('text/plain')
                        const pastedWordCount = getWordCount(pastedText)
                        const currentWordCount = getWordCount(view.state.doc.textContent);
            
                        // If the pasted content exceeds the word limit, trim it
                        if (currentWordCount + pastedWordCount  >= maxWords) {
                          // Trim the pasted text to fit within the maxWords limit
                          const excessWords = currentWordCount + pastedWordCount  - maxWords
                          const trimmedText = trimTextToWordLimit(pastedText, excessWords)
 
                            // Create a new transaction with the trimmed text
                            const { from, to } = view.state.selection;

                            const tr = view.state.tr;

                            // Delete the content in the selected range
                            tr.delete(from, to);

                            // Insert the trimmed content at the selected position (or at the cursor if nothing is selected)
                            tr.insertText(trimmedText, from);
                            // Apply the transaction
                            view.dispatch(tr);

                            // Return true to indicate that the paste event was handled
                            event.preventDefault(); // Prevent the default paste behavior
                            return true;
                            // return trimmedText;
                        }
            
                        return false // Continue normal paste handling after modification
                      }
                  }

            })
        ]
    },

})

function getWordCount(content){
    return content.trim().split(/\s+/).filter(Boolean).length
}

function trimTextToWordLimit(text, excessWords) {
    const words = text.trim().split(/\s+/)
    const newText = words.slice(0, words.length - excessWords).join(' ')
    return newText
  }

export default wordLimitExtension
