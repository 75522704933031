<template>
  <div class="folder-tree-section">
    <button v-if="permissions" @click="openCreateFolderModal()" class="folder-tree-section__add-btn">
      <i class="icon icon-add_plus"></i>
      {{ addFolderText }}
    </button>
    <div v-if="searchingFolderList" class="folder-tree-section__skeleton">
      <a-skeleton active :title="null" :paragraph="{
          rows: 6,
          width: ['100%', '80%', '70%', '100%', '100%', '60%'],
        }"
      />
    </div>
    <a-tree v-else-if="folderList.length > 0" :tree-data="folderList" 
      show-icon
      block-node
      draggable
      @drop="onDrop"
      :key="reRender"
      v-model:expandedKeys="expandedKeys"
      >
      <template #folderIcon="{ folder }">
        <img v-if="activeFolderTab !== 'organization_folders'" @click="setFolderUrl(folder)" src="@/assets/icons/glocal-folder-icon.svg" alt="Icon" />
        <img v-else :src="folder?.icon ?? require(`@/assets/icons/glocal-folder-icon.svg`)" alt="Icon" >
      </template>
      <template #title="{ folder }">
        <span @click="setFolderUrl(folder)" class="title" :class="{'active': selectedFolderId === folder?.id}"><span>{{ folder.name }}</span></span>
        <a-dropdown
          v-if="permissions"
          @click.stop
          :trigger="['click']"
          placement="bottomRight"
        >
          <a class="ant-dropdown-link">
            <i class="icon icon-vertical_three-dots"></i>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item v-if="folder.isParentFolder" @click.prevent="openCreateSubFolderModal(folder)" key="0">
                <a>{{ addSubFolderText(folder) }}</a>
              </a-menu-item>
              <a-menu-item  @click.prevent="openEditFolderModal(folder)" key="2">
                <a>Edit</a>
              </a-menu-item>
              <a-menu-item v-if="folder?.show_archive_button" @click.stop="archiveFolder(folder)" key="3">
                <a>Archive Folder</a>
              </a-menu-item>
              <a-menu-item v-if="folder?.show_duplicate_button" @click.stop="openDuplicateFolderModal(folder)" key="4">
                <a>Duplicate Folder</a>
              </a-menu-item>
              <a-menu-item @click.stop="openInviteCategoryModal(folder)" key="5">
                <a>Invite Contacts</a>
              </a-menu-item>
              <a-menu-item @click.stop="openDeleteFolderModal(folder)" key="6">
                <a class="remove-btn">
                  Remove
                </a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <button class="drag-btn">
          <img src="@/assets/icons/drag-handle-icon.svg" />
        </button>
      </template>
    </a-tree>
    <p v-if="!searchingFolderList && folderList.length === 0" class="folder-tree-section__message">{{ infoMessage }}</p>

  </div>

  <AddNewFolder 
   :visible="visibleCreateNewFolderModal"
   :action="'add'"
   :loader="isFolderBtnLoading"
   :modalTitle="modalTitle"
   @createFolder="createNewFolder"
   @closeModal="closeNewFolderModal()"
  />

  <EditFolderModal 
   :visible="visibleEditFolderModal"
   :action="'edit'"
   :folderToBeEdit="folderToBeEdit"
   :loader="isFolderBtnLoading"
   :modalTitle="modalTitle"
   @EditFolder="UpdateFolder"
   @closeModal="closeNewFolderModal()"
  />

  <delete-modal
    :visible="visibleRemoveFolderModal"
    @handle-close="visibleRemoveFolderModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="onRemoveFolder"
    @delete-record="deleteFolder"
  ></delete-modal>

  <invitationModal 
    :visible="inviteCategoryModal"
    @handle-close="inviteCategoryModal = false"
    :options="contactArray"
    :categoryId="selectedFolderId"
    :inviteType="'folderInvite'"
  />

  <duplicate-folder-modal
    :visible="visibleDuplicateFolderModal"
    @duplicateFolder="duplicateFolder"
    :duplicateModalLoader="duplicateModalLoader"
    :errorMessage="duplicateFolderErrorMessage"
    @closeModal="closeDuplicateFolderModal()"
  >
  </duplicate-folder-modal>

</template>

<script>
import folderMixin from '../../mixins/folders.mixnin.js';
import {mapActions, mapGetters} from "vuex"
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    AddNewFolder: defineAsyncComponent(() => import('@/components/GlocalFolders/AddNewFolder.vue')),
    EditFolderModal: defineAsyncComponent(() => import('@/components/GlocalFolders/AddNewFolder.vue')),
    DeleteModal: defineAsyncComponent(() => import('@/components/BaseComponents/DeleteModal.vue')),
    duplicateFolderModal: defineAsyncComponent(() => import('@/components/BaseComponents/DuplicateFolderModal.vue')),
    invitationModal: defineAsyncComponent(() => import('@/components/PdbComponents/InviteToDiscussionModal.vue'))
  },
  data() {
    return {
      visibleCreateNewFolderModal: false,
      modalTitle: "",
      isFolderBtnLoading: false,
      showMessageModal: false,
      message: {},
      selectedKeys: [],
      parents: [],
      folderParentId: "",
      isNewFolder: false,
      isParentFolder: false,
      isChildFolder: false,
      reRender: 0,
      subFolderName: '',
      folderToBeRemoved: {},
      visibleRemoveFolderModal: false,
      folderToBeEdit: {},
      visibleEditFolderModal: false,
      onRemoveFolder: false,
      folderId: '',
      visibleDuplicateFolderModal: false,
      folderToBeDuplicate:{},
      duplicateModalLoader: false,
      duplicateFolderErrorMessage:'',
      expandedKeys: [],
      categoryToBeUpdateOrder: '',
      inviteCategoryModal: false,
      options:[]
    };
  },
  mixins: [folderMixin],
  props: {
    tabName: String,
  },
  computed: {
    ...mapGetters({
      activeFolderTab: 'folders/activeFolderTab',
      folderDiscussionUsers: 'folders/getUsersWithFolderAccess'
    }),
    searchingFolderList() {
      return this.$store.getters["folders/getFolderLoader"];
    },
    folderList: {
      get() {
        const folders = this.$store.getters["folders/getFolderList"].map((folder, index) => {
          const newFolder = {
            folder: {
              name: folder.name,
              id: folder.id,
              totalArticles: folder.total_articles,
              parentFolderId: "",
              isParentFolder: true,
              isChildFolder:  false,
              parents: [],
              topic: folder.topic,
              icon: folder?.icon,
              show_duplicate_button: this.activeFolderTab === 'organization_folders' ? false : true,
              show_archive_button: this.activeFolderTab === 'organization_folders' ? false : true,
              folder_ordering_id: folder?.folder_ordering_id
            },
            key: folder.id,
            topic: folder.topic,
            folder_tab: folder.folder_tab,
            slots: { icon: "folderIcon" },
            children: folder.child ? folder.child.map((child, childIndex) => {
              const children = {
                folder: {
                  name: child.name,
                  id: child.id,
                  totalArticles: child.total_articles,
                  parentFolderId: folder.id,
                  isParentFolder: true,
                  isChildFolder:  false,
                  parents: [],
                  topic: child.topic,
                  icon: child?.icon,
                  show_duplicate_button: this.activeFolderTab === 'organization_folders' ? true : false,
                  show_archive_button: this.activeFolderTab === 'organization_folders' ? true : false,
                  folder_ordering_id: child?.folder_ordering_id
                },
                key: child.id,
                slots: { icon: "folderIcon" },
                children: child.child.map((nestedChild, nestedIndex) => {
                  const nestedChildren = {
                    slots: { icon: "folderIcon" },
                    folder: {
                      name: nestedChild.name,
                      id: nestedChild.id,
                      totalArticles: nestedChild.total_articles,
                      parentFolderId: folder.id,
                      childFolderId: child.id,
                      isParentFolder: false,
                      isChildFolder:  true,
                      parents: [],
                      topic: nestedChild.topic,
                      icon: nestedChild?.icon,
                      show_duplicate_button: false, 
                      show_archive_button: false,
                      folder_ordering_id: nestedChild?.folder_ordering_id
                    },
                    topic: nestedChild.topic,
                    key: nestedChild.id,
                  };
                  return nestedChildren;
                })
              };
              return children;
            }) : []
          };
          return newFolder;
        });

        return folders;
      },
      async set(folders) {

          console.log("FOLDERs", folders)
        // folders.map(folder=>folder.child)
        if (this.categoryToBeUpdateOrder){
          let orderFolder = null
          let orderFolderIndex = null
          let childParent = null

          // console.log("CATEGORY TO BE UPDATEd", this.categoryToBeUpdateOrder, folders)
          folders.forEach((folder, index)=>{
            if (folder?.id === this.categoryToBeUpdateOrder){
              orderFolder = {...folder}
              orderFolderIndex = index
            }
            else{
              folder?.children.forEach((child, index, array)=>{
                if (child?.id === this.categoryToBeUpdateOrder){
                    orderFolder = {...child}
                    orderFolderIndex = index
                    childParent = folder?.folder_ordering_id
                }else{
                  child?.children.forEach((grandChild, index)=>{
                    if (grandChild?.id === this.categoryToBeUpdateOrder){
                      orderFolder = {...grandChild}
                      orderFolderIndex = index
                      childParent = child?.folder_ordering_id
                }
                  })
                }
              })
            }
          })

          try{
            
            if (orderFolder){
              const serializedFolders = folders.map(folder=>(
                {
                  ...folder,
                  child: folder.children.map(folderChild=>(
                    {
                      ...folderChild,
                      child: folderChild.children.map(folderGrandChild=>(
                        {
                          ...folderGrandChild,
                          child:[]
                        }
                      ))

                    }
                  ))
                }
              ))
              
                // await this.updateFolderOrdering({'folder_ordering_id':orderFolder?.folder_ordering_id, 'parent_folder_ordering_id':childParent?.folder_ordering_id, order:orderFolderIndex+1 })
                this.$store.commit("folders/SET_FOLDER_TREE", serializedFolders);
                this.categoryToBeUpdateOrder = ''
                this.$showToast({message:'Folder position updated successfully.', type:'success'})

            }
          }
          catch(err){
            this.$showToast({message:'Something went wrong. Please try again later', type:'failure'})
            console.log(err)
          }
        }else{
          this.$store.commit("folders/SET_FOLDER_TREE", folders);
        }
      }
    },
    isInstitute() {
      return this.$store.getters["profile/userInformation"].profile.institute;
    },
    permissions() {
      if (this.tabName === 'Class Folders' && Object.keys(this.isInstitute).length == 0) {
        return false;
      }else if (this.tabName === 'Class Folders' && this.isInstitute?.permission === 'student') {
          return false;
      }
      return true;
      
    },
    infoMessage() {
      return this.activeFolderTab === 'organization_folders' ?  'No organizations found' : 'No folders found'
    },
    archivedFolders() {
      return this.$store.getters['folders/getArchivedFolder'];
    },
    parentFolder() {
      return this.$store.getters["folders/getParentFolderDetails"];
    },
    childFolder() {
      return this.$store.getters["folders/getChildFolderDetails"];
    },
    nestedChildFolder() {
      return this.$store.getters["folders/getNestedChildFolderDetails"];
    },
    addFolderText(){
      return this.activeFolderTab === 'organization_folders' ?  'Add Organization Below' : 'Add Folder Below'
    },
    discussionUsers(){
      return this.folderDiscussionUsers
    },
    contactArray(){
      return this.options.filter(user=>!this.discussionUsers.find(discussionUser => discussionUser?.id === user?.value))
    }
  },
  watch:{
    folderList:{
      handler(folders){
        this.expandedKeys = folders.flatMap(folder=>[folder?.key, ...folder.children.flatMap(child=>[child?.key, ...child.children.map(nestedChild=>nestedChild.key)])])
        // auto select the first one
        if (folders.length === 1){
          this.setFolderUrl(folders[0]?.folder)
        }
      },immediate: true
    }
  },
  mounted() {
    // this.fetchContacts()
    this.emitter.on('unarchive-folder', (folder) => {
      this.unarchiveFolder(folder);
    }); 
    this.emitter.on('delete-archive-folder', (folder) => {
      this.openDeleteFolderModal(folder);
    }); 
  },
  methods: {
    ...mapActions({
      duplicateFolderContent: 'folders/duplicateFolderContent',
      updateFolderOrdering: 'folders/updateFolderOrdering',
      setActiveFolder: 'folders/setActiveFolder',
    }),
    generateKeys(node, prefix) {
    node.key = prefix;
    if (node.children && node.children.length > 0) {
      node.children.forEach((child, index) => {
        this.generateKeys(child, `${prefix}-${index}`);
      });
    }
  },
    openCreateFolderModal() {
      this.modalTitle =  this.activeFolderTab === 'organization_folders' ? 'Add a New Organization' : `Add a New Folder`
      this.visibleCreateNewFolderModal = true;  
      this.isNewFolder = true;
      this.folderParentId = "";
    },
    openCreateSubFolderModal(folder) {
      // this.modalTitle = this.activeFolderTab === 'organization_folders' ? `Add a New Folder` : `Add a New Subfolder`
      
      this.modalTitle = this.addSubFolderText(folder, true)
      this.folderParentId = folder.id;
      this.isParentFolder = folder.isParentFolder
      this.visibleCreateNewFolderModal = true;  
    },
    closeNewFolderModal() {
      this.visibleCreateNewFolderModal = false;  
      this.visibleEditFolderModal = false;
    },
    setFolderUrl(folder) {
      this.$store.commit('folders/SET_ARCHIVE', false);
      // let tab = this.$route.params.class === 'personal' ? 'personal' : 'class';
      let tab = this.$route.params.class
      this.$router.push(`/workspaces/${tab}/${folder.id}`);
      this.createActivity(folder.id, 'folder', 'open');
    },
    async createNewFolder(folder) {
      try {
        this.isFolderBtnLoading = true;
        const formData = new FormData()
        
        Object.entries(folder).forEach(([key, value])=>{
          if(key ===  'icon' && this.activeFolderTab === 'organization_folders'){
              value.length > 0 ? formData.append(key, value[0].originFileObj) : formData.append(key, new File([""], ""));
          } 
          else if (key !==  'icon'){
            formData.append(key, value)
          }
        })

        formData.append('parent', this.folderParentId)
        const response = await this.$store.dispatch('folders/createFolder', {'folderDetails':formData});
        if (response.status === 201) {
          this.showMessageModal = true;
          this.message = {
            title: "folder created successfully.",
            type: "success",
          }

          if(!this.parents.find((parentId) => parentId === this.folderParentId ))
            this.parents.push(this.folderParentId)
          
          const folder = {
            folder: {
              name: response.data.name,
              id: response.data.id,
              isParentFolder: this.isNewFolder ? true : this.detectIfGrandChild(this.folderParentId) ? this.isParentFolder :  false,
              isChildFolder: this.isNewFolder ? false : this.detectIfGrandChild(this.folderParentId) ? false :  true,
              parentFolderId: this.folderParentId,
            },
            key: response.data.id,
            folder_tab: "",
            slots: { icon: "folderIcon" },
            children: []
          }
          if(this.isNewFolder){
            this.folderList.push(folder);
          } else if (folder.folder.isParentFolder) {
            const index = this.folderList.findIndex((findItem) => findItem.key === this.folderParentId);
            this.folderList[index].children.push(folder)
          } else if (folder.folder.isChildFolder) {        
            let parentIndex = -1
            this.folderList.forEach(grandParent => { 
              parentIndex = grandParent.children.findIndex(child => child.key === this.folderParentId)
              if (parentIndex >= 0) { 
                grandParent.children[parentIndex].children.push(folder)
                return 
              }
            })
          }
          this.$store.dispatch('folders/getFolderTree')
          // this.$route.params.class === 'personal' ?  this.$store.dispatch('folders/getPersonalFolderTree') : this.$store.dispatch('folders/getClassFolderTree', folder)
          // this.reRender++;
          this.closeNewFolderModal();
          this.$showToast({message:'Folder created successfully.', type:'success'}) 
        }
      } catch (err) {
        console.log(err);
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'}) 
        this.closeNewFolderModal();
      }finally {
        this.isFolderBtnLoading = false;
        this.folderParentId = "";
        this.isNewFolder = false;
        this.isChildFolder = false;
        this.isParentFolder = false;
      }
    },
    detectIfGrandChild(folderId) {
      return this.folderList.find(parentFolder=>parentFolder.key === folderId )
    },
    openEditFolderModal(folder) {
      this.folderToBeEdit = {
        name: folder.name,
        id: folder.id,
        isParentFolder: folder.isParentFolder,
        parentFolderId: folder.parentFolderId,
        folderChildId: folder.childFolderId,
        isChildFolder: folder.isChildFolder,
        topic: folder.topic,
        icon: folder?.icon
      }
      this.modalTitle = !folder.parentFolderId && this.activeFolderTab === 'organization_folders' ? 'Edit Organization' : `Edit Folder`
      this.visibleEditFolderModal = true;
    },
    async UpdateFolder(folder) {
    try {
      this.isFolderBtnLoading = true;
      
      // const payload = {
      //   folder_id: this.folderToBeEdit.id,
      //   name: folder.folderName,
      //   folderType: this.folderType,
      //   topic: folder.selectedTopicType
      // };


      const formData = new FormData()
        
      Object.entries(folder).forEach(([key, value])=>{
        if(key ===  'icon' && this.activeFolderTab === 'organization_folders'){
              value.length > 0 ? formData.append(key, value[0].originFileObj) : formData.append(key, new File([""], ""));
        } 
        else if (key !==  'icon'){
          formData.append(key, value)
        }

      })

      formData.append('parent', this.folderParentId)

      // const updatePersonalFolder = 'folders/editPersonalFolder';
      // const updateClassFolder = 'folders/editClassFolder';
      // const apiCall = this.tabName === 'Class Folders' ? updateClassFolder : updatePersonalFolder;

      const response = await this.$store.dispatch('folders/editFolder', {payload:formData, folderId:this.folderToBeEdit.id});

      if (response.status === 200) {
        if (this.folderToBeEdit.isParentFolder && this.folderToBeEdit.parentFolderId === "") {
          const folderIndex = this.folderList.findIndex(obj => obj.folder.id === response.data.id);
          if (folderIndex !== -1) {
            this.folderList[folderIndex].folder.name = response.data?.name;
            this.folderList[folderIndex].folder.icon = response.data?.icon;
            if (this.$route.params.id === this.folderToBeEdit.id) {
              this.parentFolder.folder.name = response.data?.name
              this.folderList[folderIndex].folder.icon = response.data?.icon;
            }
          }
        } else if (this.folderToBeEdit.isParentFolder) {
          const parentIndex = this.folderList.findIndex(folder => folder.folder.id === this.folderToBeEdit.parentFolderId);
          const childIndex = this.folderList[parentIndex].children.findIndex(child => child.folder.id === this.folderToBeEdit.id);

          if (childIndex !== -1) {
            this.folderList[parentIndex].children[childIndex].folder.name = response.data?.name;
            this.folderList[parentIndex].children[childIndex].folder.icon = response.data?.icon;
            if (this.$route.params.id === this.folderToBeEdit.id) {
              this.folderList[parentIndex].children[childIndex].folder.name = response.data?.name;
              this.folderList[parentIndex].children[childIndex].folder.icon = response.data?.icon;
            }
          }
        } else if (this.folderToBeEdit.isChildFolder) {
          let parentIndex = -1
            this.folderList.forEach(grandParent => {
              parentIndex = grandParent.children.findIndex(child => child.folder.id === this.folderToBeEdit.folderChildId)
              if (parentIndex >= 0) {
                grandParent.children[parentIndex].children.find((nestedChild) => {
                  this.nestedChild.folder.name = response.data?.name;
                  this.nestedChild.folder.icon = response.data?.icon;
                })
                if (this.$route.params.id === this.folderToBeEdit.id) {
                  this.nestedChild.folder.name = response.data?.name;
                  this.nestedChild.folder.icon = response.data?.icon;
                }
                return 
              }
            })
        }
        this.reRender++;
      }
      this.isFolderBtnLoading = false;
      this.closeNewFolderModal();
      this.$showToast({message:'Folder updated successfully.', type:'success'}) 
    } catch (err) {
      this.isFolderBtnLoading = false;
      this.closeNewFolderModal();
      this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'}) 
    }
    },
    async archiveFolder(folder) {
      try {
        const payload = {
          id: folder.id,
          is_archived: true
        }
        const response = await this.$store.dispatch('folders/archiveOrUnchiveFolder', payload);
          
          if (folder.parentFolderId) {
            const parentIndex = this.folderList.findIndex(idx => idx?.folder?.id === folder.parentFolderId);
            const childIndex = this.folderList[parentIndex].children.findIndex(child => child?.folder?.id === folder.id);
            if (childIndex !== -1) {
              this.folderList[parentIndex].children.splice(childIndex, 1);
            }
          } else {
            const updatedFolderList = this.folderList.findIndex((val) => val?.folder?.id === folder.id);
            if (updatedFolderList !== -1) {
              this.folderList.splice(updatedFolderList, 1);
              this.archivedFolders.push(response);
            }
          }
          this.reRender++;
          this.$showToast({message:'Folder archived successfully.', type:'success'}) 
        
      } catch (err) {
        console.log(err);
      }
    },
    async unarchiveFolder(folderToBeUnarchive) {
      try {
        const payload = {
          id: folderToBeUnarchive.id,
          is_archived: false
        }
        const response = await this.$store.dispatch('folders/archiveOrUnchiveFolder', payload);
        this.$showToast({message:'Folder unarchive successfully.', type:'success'}) 
          this.folderList = [...this.folderList.map(folder=>(
            {
              ...folder.folder,
              child: [...folder.children.map(child=>({
                ...child.folder,
                child: [...child.children.map(grandChild=>grandChild?.folder)]
              }))]
            }
          )), response]
          const updatedArchivedList = this.archivedFolders.findIndex((val) => val.id === folderToBeUnarchive.id);
          if (updatedArchivedList !== -1) {
            this.archivedFolders.splice(updatedArchivedList, 1);
          }
      } catch (err) {
        console.log(err);
      }
    },
    async openDeleteFolderModal(folder) {
      this.folderToBeRemoved = {
        id: folder.id,
        isParentFolder: !folder.parentFolderId,
        isChildFolder: folder.isParentFolder,
        isNestedFolder: folder.isChildFolder,
        parentFolderId: folder.parentFolderId,
        childFolderId: folder.childFolderId,
        isArchivedFolder: folder.isArchivedFolder
      }
      this.visibleRemoveFolderModal = true;
    },
    async deleteFolder() {
      try {
        this.onRemoveFolder = true;
        const payload = {
          folder_id : this.folderToBeRemoved.id
        }
        const deletePersonalFolder = 'folders/deletePersonalFolder';
        // const deleteClassFolder = 'folders/deleteClassFolder';
        // const apiCall = this.tabName === 'Class Folders' ? deleteClassFolder : deletePersonalFolder;

        const response = await this.$store.dispatch(deletePersonalFolder, payload);
        if (response.status === 204) {
          this.visibleRemoveFolderModal = false;
          if (this.folderToBeRemoved.isParentFolder) {
              this.folderList = this.folderList.filter(folder=>folder?.folder?.id !== this.folderToBeRemoved.id).map(folder=>({
                ...folder?.folder,
                child: folder?.children.map(child=>(
                  {
                    ...child?.folder,
                    child: child?.children?.map(grandChild=>(
                      {
                        ...grandChild?.folder,
                        child:[]
                      }
                    ))
                  }
                ))
              }))
              
              if (this.$route.params?.id === this.folderToBeRemoved.id){
                const tab = this.$route.params.class
                this.$router.push({path:`/workspaces/${tab}/`})
                this.$store.commit("folders/SET_PARENT_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_CHILD_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_NESTED_CHILD_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_FOLDER_ARTICLE_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_MEDIA_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_PODCASTS_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_SUGGESTED_ARTICLE_CONTENT", []);
              }
          } else if (this.folderToBeRemoved.isChildFolder) {
            const parentIndex = this.folderList.findIndex(folder => folder?.folder?.id === this.folderToBeRemoved.parentFolderId);
            const childIndex = this.folderList[parentIndex].children.findIndex(child => child?.folder?.id === this.folderToBeRemoved.id);
            if (childIndex !== -1) {
              const isGrandChildSelected = this.folderList[parentIndex].children[childIndex].children.some(child=>child?.folder?.id === this.$route.params?.id)
              this.folderList[parentIndex].children.splice(childIndex, 1);
              if (isGrandChildSelected || this.$route.params?.id === this.folderToBeRemoved.id){
                const tab = this.$route.params.class
                this.$router.push({path:`/workspaces/${tab}/`})
                this.$store.commit("folders/SET_PARENT_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_CHILD_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_NESTED_CHILD_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_FOLDER_ARTICLE_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_MEDIA_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_PODCASTS_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_SUGGESTED_ARTICLE_CONTENT", []);
              }
            }
          } else if (this.folderToBeRemoved.isNestedFolder) {
            const parentIndex = this.folderList.findIndex(folder => folder?.folder?.id=== this.folderToBeRemoved.parentFolderId);
            const childIndex = this.folderList[parentIndex].children.findIndex(child => child?.folder?.id === this.folderToBeRemoved.childFolderId);
            const nestedChildIndex = this.folderList[parentIndex].children[childIndex].children.findIndex(nestedChild => nestedChild?.folder?.id === this.folderToBeRemoved.id);
            if (nestedChildIndex !== -1) {
              this.folderList[parentIndex].children[childIndex].children.splice(nestedChildIndex, 1);
              if (this.$route.params?.id === this.folderToBeRemoved.id){
                const tab = this.$route.params.class
                this.$router.push({path:`/workspaces/${tab}/`})                
                this.$store.commit("folders/SET_PARENT_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_CHILD_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_NESTED_CHILD_FOLDER_DETAILS", {});
                this.$store.commit("folders/SET_FOLDER_ARTICLE_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_MEDIA_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_PODCASTS_CONTENT", []);
                this.$store.commit("folders/SET_FOLDER_SUGGESTED_ARTICLE_CONTENT", []);
              }
            }
          }
          if (this.folderToBeRemoved.isArchivedFolder) {
            const updatedArchivedList = this.archivedFolders.findIndex((val) => val.id === this.folderToBeRemoved.id);
            if (updatedArchivedList !== -1) {
              this.archivedFolders.splice(updatedArchivedList, 1);
            }
          }
          this.reRender++;
          this.onRemoveFolder = false;
          this.$showToast({message:'Folder removed successfully.', type:'success'}) 
          
        }
      } catch (err) {
        console.log(err);
      }
    },
    openDuplicateFolderModal(folder) {
      this.visibleDuplicateFolderModal = true;
      this.folderToBeDuplicate = JSON.parse(JSON.stringify(folder))
    },
    closeDuplicateFolderModal() {
      this.visibleDuplicateFolderModal = false;
      this.folderToBeDuplicate = {}
      this.duplicateFolderErrorMessage = ''
    },
    async duplicateFolder(folder_tab){
      try{
        if (folder_tab){
          this.duplicateModalLoader = true
          await this.duplicateFolderContent({folderId: this.folderToBeDuplicate?.id, folder_tab })
          this.duplicateModalLoader = false
          this.closeDuplicateFolderModal()
          this.$showToast({message:"Selected folder has been duplicated successfully.", type: 'success'})
        }else{
          this.duplicateFolderErrorMessage = 'Please select folder tab.'
        }
      }
      catch(err){
        this.duplicateModalLoader = false
        if (err.response?.data?.error.length > 0){
          this.duplicateFolderErrorMessage = err.response?.data?.error[0] ?? ''
        }
        else{
          this.closeDuplicateFolderModal()
          this.$showToast({message:"Something went wrong. Please try again later.", type: 'failure'})
        }

      }
    },
    addSubFolderText(folder, modalTitle=false){
      if (this.activeFolderTab === 'organization_folders' && !folder?.parentFolderId){
        return modalTitle  ? 'Add a New Folder' : 'Add Folder'
      }
      else{
        return modalTitle  ? 'Add a New Subfolder' : 'Add Subfolder'
      }
    },
    onDrop (info) {
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
      let isValid = true

      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr)
          }
        if (item.children) {
          return loop(item.children, key, callback);
        }
        })
      }
      let data = JSON.parse(JSON.stringify(this.folderList))

      //serializing data array
      data = data.map((folder)=>(
          {
            ...folder.folder,
            key:folder.folder?.id,
            children: folder.children.map((child, index)=>({
              ...child.folder,
                key:child.folder?.id,
                children: child.children.map((grandChild, index)=>({
              ...grandChild.folder,
                 key: grandChild.folder?.id,
                 children: grandChild.children ?? []
              }))
            }))
          }
      ))

      let dragObj = ''
        loop(data, dragKey, (item, index, arr) => {
        if (info.dropToGap) {
          arr.splice(index, 1)
          dragObj = item
        }
      })
        
      if (info.dropToGap) {
        let ar
        let i
        loop(data, dropKey, (item, index, arr) => {
          ar = arr
          i = index
        })

        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj)
        } 
        else {
            ar.splice(i + 1, 0, dragObj)
        }


        data.forEach((folder, index, array)=>{
              const isOrganization = !dragObj.show_archive_button && !dragObj.show_duplicate_button && !dragObj.parentFolderId 
              folder.children.forEach((child, index, array)=>{
                if (child.id === dragKey){
                  const hasGrandChildren = child.children.some(child=>child.children.length > 0) 
                  if (hasGrandChildren || isOrganization){
                    isValid = false
                    const message = isOrganization ? 'Can not move an organization to another organization.' : 'Can not move a folder to another folder.'
                    this.$showToast({message, type:'failure'})
                  } else{
                    isValid = true
                  }
                }
                else{
                  child.children.forEach((grandChild, index, array)=>{
                    if (grandChild?.id === dragKey ){
                      const exceedTreeDepth = array.some(child=>child.children.length > 0)
                      if (exceedTreeDepth || isOrganization){
                        isValid = false
                        const message = isOrganization ? 'Can not move an organization to another organization.' : 'Can not move a folder to another folder.'
                        this.$showToast({message, type:'failure'})
                      }
                      else{
                        isValid = true
                      }
                    }
                  })
                }
                
              })
        })


        if (isValid){
          this.categoryToBeUpdateOrder = dragKey
          this.folderList = [...data]
        }
      }
    },

    openInviteCategoryModal(folder){
      this.inviteCategoryModal = true
      this.setActiveFolder(folder)

    }

  }
};
</script>

<style lang="scss">
.folder-tree-section {
  padding: 0.4rem 2.9rem 2.4rem;
  &__add-btn {
    background-color: $color-light-blue;
    border: none;
    outline: none;
    width: 100%;
    font-size: 1.4rem;
    font-family: $font-primary-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    height: 4rem;
    border-radius: 100px !important;
    margin-bottom: 2.4rem;
    cursor: pointer;
    .icon {
      color: $color-primary;
      font-size: 1.1rem;
      margin-right: 0.9rem;
      margin-top: -2px;
    }
  }
  &__message {
    font-size: 1.4rem;
    font-family: $font-primary-medium;
    margin-bottom: 0;
    text-align: center;
  }
  &__skeleton {
    .ant-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-paragraph {
          li {
            height: 2rem;
            margin: 2rem 0;
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .ant-tree > li:last-child {
    margin-bottom: 0 !important;
  }

  .ant-tree {
    height: calc(100vh - 43rem);
    overflow-y: auto;
    li {
      margin-bottom: 1.2rem !important;
    }
    .ant-tree-treenode-switcher-open,
    .ant-tree-treenode-switcher-close {
      padding: 1.2rem 1.4rem 0.2rem;
      background-color: #F5F5F5;
      border-radius: 0.8rem;
      margin-bottom: 0;
      .ant-tree-switcher {
        width: 2rem;
        height: 2.4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .anticon {
          font-size: 1.6rem !important;
        }
      }

      .ant-tree-node-content-wrapper {
        padding: 0;
        margin-bottom: 1rem;
        padding: 0 1rem;
        display: inline-flex;
        align-items: center;
        position: relative;
        border-radius: 0.5rem;
        height: 2.4rem;
        &:hover {
          background-color: rgb(217, 217, 217);
        }

        .ant-tree-icon__customize {
          width: 1.8rem;
          height: 1.8rem;
          margin: 0 1.2rem 0 0.8rem;
          position: relative;
          z-index: 1;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .ant-tree-title {
          width: calc(100% - 5rem);
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: auto;
          .title {
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            line-height: normal;
            color: $color-black;
            width: 100%;
            overflow: hidden;
            
            span {
              color: $color-black;
              position: relative;
              z-index: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              width: 100%;
            }
          }
          .active {
            &::before {
              position: absolute;
              background-color: rgb(217, 217, 217);
              content: '';
              left: 0;
              top: -1px;
              height: calc(100% + 1px);
              width: 100%;
              border-radius: 0.5rem;
            }
          }

          .ant-dropdown-link {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-black;
            margin-left: auto;
            height: fit-content;
            width: fit-content;
            line-height: normal;
            position: relative;
            z-index: 1;
            .icon {
              font-size: 1.3rem;
            }
          }
          .drag-btn {
            display: flex;
            width: 1.3rem;
            flex-direction: column;
            border: none;
            outline: none;
            margin-left: 1.3rem;
            height: 1.4rem;
            width: 1.4rem;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            position: relative;
            z-index: 1;
            img {
              width: 100%;
            }
          }
        }
      }

      .ant-tree-node-selected {
        background-color: transparent;
      }
      .ant-tree-child-tree {
        li {
          padding: 0;
          margin-bottom: 0 !important;
        }
      }
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff3f;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: rgb(224, 224, 224);
      border-radius: 3rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3rem;
    }
  }
  &__message {
    font-size: 1.4rem;
    font-family: $font-primary;
    color: $color-black;
    opacity: 0.7;
  }
}
</style>
