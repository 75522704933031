<template>
  <a-modal
    :visible="visible"
    @cancel="closeModal"
    :footer="null"
    class="view-all-users-modal"
    :title="null"
  >
    <div class="view-all-users-modal-container">
      <div class="view-all-users-modal-container__header">
        <h2 class="view-all-users-modal-container__header--title">
          {{ modalTitle }}
        </h2>
      </div>
      <div class="view-all-users-modal-container__body">
        <div class="view-all-users-modal-container__body--skeleton" v-if="loadingUserSkeleton">
          <skeleton-loader
            v-for="index in 4"
            :key="index"
          ></skeleton-loader>
        </div>
        <div
          v-if="!loadingUserSkeleton && users.length === 0"
          class="view-all-users-modal-container__body--message"
        >
          No users found.
        </div>
        <div class="view-all-users-modal-container__body--users" v-else-if="!loadingUserSkeleton && users.length > 0">
          <div class="user" v-for="(user, index) in users" :key="index">
            <div class="user__image">
              <img :src="user.profile_picture || user?.picture" alt="Image" />
            </div>
            <div class="user__name">
              <h1>{{ user.full_name  || user?.name}}</h1>
              <p>{{ user.job_title  ||  user?.message}}</p>
            </div>
            <a-button v-if="showAddButtonModules.includes(module)" :loading="user.isLoading" :disabled="user.isLoading" @click="addRecommendation(user)" class="user__btn">Follow</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import skeletonLoader from "../BaseComponents/ConnectionCardSkeleton.vue"
export default {
  props: {
    visible: Boolean,
    users: Array, // Pass users as prop
    loadingUserSkeleton: Boolean,
    module:{
      type: String,
      default: () =>('')
    },
    modalTitle:{
      type: String,
      default: () => ('All Invited Users')
    }
  },
  components: { 
    skeletonLoader
  },
  data:()=>({
    showAddButtonModules: ['recommendedContacts', 'recommendedOrganization']
  }),
  methods: {
    closeModal() {
      this.$emit("closeAllUserModal", false);
    },
    addRecommendation(user){
      this.$emit('add-recommendation', user)
    }
  },
};
</script>

<style lang="scss">
.view-all-users-modal {
  width: 45rem !important;
  top: 8rem !important;

  .ant-modal-content {
    box-shadow: 0px 0px 94px #00000047;

    .ant-modal-close {
      margin: 1.5rem 1.5rem 0 0 !important;
    }

    .ant-modal-body {
      .view-all-users-modal-container {
        border-radius: 1rem;

        &__header {
          padding: 2rem 2.5rem;

          &--title {
            font-size: 2.2rem;
            font-family: $font-primary-bold;
            line-height: normal;
            margin-bottom: 0;
            color: $color-black;
          }
        }

        &__body {
          padding-bottom: 2.5rem;
          &--message {
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: 2.2rem;
            margin-bottom: 0;
            color: $color-black;
            padding: 0 2rem;
            margin: 4rem 0;
            text-align: center;
          }
          &--users {
            overflow-y: auto;
            max-height: calc(100vh - 24rem);
            .user {
              display: flex;
              align-items: center;
              padding: 1.5rem 2.6rem;
              border-bottom: 1px solid $color-dark-grey-5;
              &:last-child {
                border-bottom: none;
              }
              &__image {
                width: 4rem;
                height: 4rem;
                border-radius: 100%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 100%;
                  background-color: #ececec;
                }
              }

              &__name {
                margin-left: 1rem;

                h1 {
                  font-family: $font-primary-medium;
                  font-size: 1.6rem;
                  color: $color-black;
                  line-height: 2rem;
                  margin-bottom: 0;
                }

                p {
                  font-family: $font-primary;
                  font-size: 1.4rem;
                  color: $color-black;
                  line-height: 1.8rem;
                  margin-bottom: 0;
                  opacity: 0.6;
                }
              }

              &__btn {
                width: 6rem;
                background-color: $color-primary;
                border: none;
                display: block;
                height: 3.4rem;
                font-size: 1.4rem;
                font-family: $font-primary-medium !important;
                color: $color-white;
                margin-left: auto;
                padding: 0 !important;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                span {
                  line-height: normal;
                }
                .anticon {
                  .anticon-spin {
                    font-size: 1.7rem !important;
                    color: $color-primary !important;
                  }
                }
              }
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            &::-webkit-scrollbar {
              width: 0.4rem;
              height: 0.4rem;
              background-color: transparent;
              border-radius: 3rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 3rem;
            }
          }
          &--skeleton {
            padding: 1.5rem 2.5rem 0;
            .connection-card-skeleton {
              margin: 0 0 2rem !important;
              .ant-skeleton-header {
                .ant-skeleton-avatar {
                  border-radius: 100%;
                }
              }
            }
          }
        }

        &__footer {
          padding: 2.5rem 2.5rem;
          border-top: 1px solid $color-dark-grey-5;
          margin-top: -1px;

          .ant-btn {
            width: 10rem;
            background-color: $color-primary;
            border: none;
            outline: none;
            height: 4rem;
            font-size: 1.4rem;
            font-family: $font-primary-medium !important;
            color: $color-white;
            margin-left: auto;
            display: block;

            span {
              line-height: normal;
            }

            .anticon {
              .anticon-spin {
                font-size: 1.8rem;
                color: $color-primary;
              }
            }

            &:hover,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
          }

          .disable-save-btn {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>
