<template>
  <div class="folder-sidebar">
    <div class="folder-sidebar__header">
      <!-- <button @click="navigateToProfile()" class="folder-sidebar__header--back-btn">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.8248 8.9998L8.7248 13.8998C8.9248 14.0998 9.02064 14.3331 9.01231 14.5998C9.00397 14.8665 8.89981 15.0998 8.69981 15.2998C8.49981 15.4831 8.26647 15.579 7.9998 15.5873C7.73314 15.5956 7.4998 15.4998 7.2998 15.2998L0.699805 8.69981C0.599805 8.5998 0.528971 8.49147 0.487305 8.3748C0.445638 8.25814 0.424805 8.13314 0.424805 7.9998C0.424805 7.86647 0.445638 7.74147 0.487305 7.6248C0.528971 7.50814 0.599805 7.3998 0.699805 7.2998L7.2998 0.699805C7.48314 0.516471 7.7123 0.424805 7.9873 0.424805C8.2623 0.424805 8.49981 0.516471 8.69981 0.699805C8.89981 0.899805 8.9998 1.1373 8.9998 1.4123C8.9998 1.6873 8.89981 1.9248 8.69981 2.1248L3.8248 6.9998H14.9998C15.2831 6.9998 15.5206 7.09564 15.7123 7.2873C15.904 7.47897 15.9998 7.71647 15.9998 7.9998C15.9998 8.28314 15.904 8.52064 15.7123 8.71231C15.5206 8.90397 15.2831 8.9998 14.9998 8.9998H3.8248Z"
            fill="#1C1B1F" />
        </svg>
      </button> -->
      <div class="folder-sidebar__header--folder-name">
        <h3>Folder Categories</h3>
        <button @click="closeSideBar" class="close-btn">
          <i class="icon icon-add_plus"></i>
        </button>
      </div>

    </div>
    <div class="folder-sidebar__body">
      <a-tabs  @change="handleTabChange" v-model:activeKey="activeKey" class="folder-sidebar__body--tabs">
          <a-tab-pane v-for="tab in folderTabs" :key="tab.value">
            <template #tab>
              <div class="tab-name">
                <p>{{tab.label}}</p>
                <!-- <a-dropdown :trigger="['click']" placement="bottomRight" class="dropdown">
                  <a class="ant-dropdown-link" @click.stop>
                    <i class="icon icon-vertical_three-dots"></i>
                  </a>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item key="0">
                        <a class="remove-btn">Remove</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown> -->
              </div>
            </template>
            <!-- <FolderTree :tabName="'Class Folders'" /> -->
          </a-tab-pane>
      </a-tabs>
      <!-- "Class" and "Personal" tabs are managed in a single instance of the FolderTree -->
      <FolderTree :tabName="folderTreeTabName()" />
    </div>
    <div @click="visibleArchiveFolders()" class="folder-sidebar__footer">
        <button class="folder-sidebar__footer--btn">
          <div class="content">
            <img src="@/assets/icons/archive-icon.svg" />
            <div>
              <h2>Archives</h2>
              <p>{{ archivedFolders.length }} Folders</p>
            </div>
          </div>
          <img src="@/assets/icons/expand_more.svg" class="icon" />
        </button>
    </div>
  </div>
  <add-new-tab-modal
    :visible="visibleAddNewTabModal"
    @closeModal="closeAddNewTabModal()"
  >
  </add-new-tab-modal>
</template>
<script>
import FolderTree from "./FolderTree";
import foldersMixin from "../../mixins/folders.mixnin";
import AddNewTabModal from "../BaseComponents/AddNewTabModal.vue";
import {mapGetters, mapActions} from 'vuex' 

export default {

  components: {
    FolderTree,
    AddNewTabModal
  },
  watch: {
    activeKey: {
      async handler(activeTab) {
        await this.emptyFolderTreeAndContent()
        // console.log(activeTab, "ACTIVE TAB")
        let tabName = null
        switch(activeTab){
          case 'private_folders':
            tabName = 'Private Folder'
            break;
          case 'public_folders':
            tabName = 'Public Folder'
            break;
          case 'organization_folders':
            tabName= 'Organization Folders'  
            break;
        }
        this.getFolderTree(activeTab);
        this.$store.commit("folders/SET_TAB_NAME", tabName);
        this.getArchiveFolders(activeTab);
        // if (Object.keys(this.isInstitute).length > 0 && tabName === 'class_folders') {
        //   this.getInstituteUsers();
        // }
      },immediate:true
    },
    $route: {
      immediate: true,
      handler(to, _) {
        if (to.params.class) {
          this.handleRouteParams(to);
        }
      },
    },
  },
  data() {
    return {
      // activeKey: '',
      activeTabId: "",
      visibleAddNewTabModal: false
    }
  },
  mixins:[foldersMixin],
  computed:{
    ...mapGetters({
      activeFolderTab: 'folders/activeFolderTab'
    }),
    userId(){
      return this.$store.getters['auth/getUserId'];
    },
    archivedFolders() {
      return this.$store.getters['folders/getArchivedFolder'];
    },
    isInstitute() {
      return this.$store.getters["profile/userInformation"].profile.institute;
    },
    folderTabs(){
      return this.$store.getters['folders/getFolderTabs']
    },
    folderList: {
      get() {
        const folders = this.$store.getters["folders/getFolderList"].map(folder => {
          const newFolder = {
            folder: {
              name: folder.name,
              id: folder.id,
              totalArticles: folder.total_articles,
              parentFolderId: null,
              isParentFolder: true,
              icon: folder?.icon
            },
            key: folder.id,
            folder_tab: folder.folder_tab,
            slots: { icon: "folderIcon" },
            topic: folder.topic,
            children: folder.child ? folder.child.map(child => {
              const children = {
                folder: {
                  name: child.name,
                  id: child.id,
                  totalArticles: child.total_articles,
                  parentFolderId: folder.id,
                  isChildFolder: true,
                  icon: child?.icon
                },
                topic: child.topic,
                slots: { icon: "folderIcon" },
                key: child.id,
                children: child.child.map(nestedChild => {
                  const nestedChildren = {
                    slots: { icon: "folderIcon" },
                    folder: {
                      name: nestedChild.name,
                      id: nestedChild.id,
                      totalArticles: nestedChild.total_articles,
                      parentFolderId: folder.id,
                      childFolderId: child.id,
                      isNestedFolder: true,
                      icon: nestedChild?.icon
                    },
                    topic: nestedChild.topic,
                    key: nestedChild.id,
                  }
                  return nestedChildren;
                })
              }
              return children;
            }) : []
          }
          return newFolder;
        });
        return folders
      },
      set(val) {
        this.$store.commit("folders/SET_PERSONAL_FOLDER_TREE", val);
      }
    },
    activeKey:{
      get(){
        return this.activeFolderTab
      },
      set(activeTab){
        this.setActiveFolderTab(activeTab)
      }
    }
  },
  methods: {
    ...mapActions({
      setActiveFolderTab: 'folders/setActiveFolderTab',
      emptyFolderTreeAndContent: 'folders/emptyFolderTreeAndContent',
      setActiveFolder: 'folders/setActiveFolder',
      initiateFolderDiscussionSocket: 'folders/initiateFolderDiscussionSocket'
    }),
    handleRouteParams(to) {
      if (to.params.class === 'private') {
        this.activeKey = 'private_folders';
      } else if (to.params.class === 'public') {
        this.activeKey = 'public_folders';
      }
      else if( to.params.class === 'organization'){
        this.activeKey = 'organization_folders'
      }
      if (to.params.id && to.params.id !== 'archives' && this.folderList.length > 0) {
        this.initiateFolderDiscussionSocket(to.params.id)
        this.getFolderContent(to.params.id);
        this.getFolderDiscussions(to.params.id);
        this.getFolderUsersPermissions(to.params.id);
      }
    },
    handleTabChange(activeTab) {
      this.$store.commit('folders/SET_ARCHIVE', false);
      switch(activeTab){
        case 'private_folders':
          this.$router.push(`/workspaces/private/`)
          break;
        case 'public_folders':
          this.$router.push(`/workspaces/public/`)
          break;
        case 'organization_folders':
          this.$router.push('/workspaces/organization/')
      }
    },
    async getFolderTree() {
      try {
        // let apiCall = this.activeKey === 'class_folders' ? classFolderTree : personalFolderTree;
        const response = await this.$store.dispatch('folders/getFolderTree');
        if (response.status === 200) {
          if (this.$route.params.id && this.$route.params.id !== 'archives') {
            this.getFolderContent(this.$route.params.id);
            this.getFolderDiscussions(this.$route.params.id);
            this.getFolderUsersPermissions(this.$route.params.id);
          } else if(!this.$route.params.id && response.data.length > 0 &&  this.$route.fullPath.includes('/workspaces')) {
            this.$router.push(`${response.data[0].id}`)
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getArchiveFolders(tab) {
    try {
        // need to resolve this once API is fixed
        // let isInstitute = tab === 'class_folders' ? 1 : 0;
        console.log("ARCHIVE FOLDER FETCHING")
        await this.$store.dispatch('folders/getArchivedFolder', this.activeKey);
      } catch (err) {
        console.log(err);
      }
    },
    async getInstituteUsers(tab) {
      try {
        await this.$store.dispatch('folders/getInstituteUsers', this.isInstitute.id);
      } catch (err) {
        console.log(err);
      }
    },
    navigateToProfile() {
      this.$router.push(`/user/${this.userId}`);
    },
    visibleArchiveFolders() {
      // let tab = this.activeKey === 'activeClassFolder' ? 'class' : 'personal';
      this.$store.commit('folders/TOGGLE_ARCHIVE');
    },
    getFolderContent(id) {
      try {
        let folderName = ''
        const payload = {
          folderId: id,
        }
        this.initiateFolderDiscussionSocket(id)
        const folder = this.folderList.find((folder) => folder.key === id);
        const parentFolder = this.folderList.find(folder => {
          return folder.children && folder.children.some(child => child.key === id);
        });

        let grandChild = null
        this.folderList.find(folder => {
          if (folder.children.length > 0) { 
            grandChild = folder.children.find(child => { 
              return  child.children && child.children.some(foundChild => foundChild.key === id);  
            })
          }
          return grandChild;
        });
        const childFolder = grandChild;

        if (folder) {
          this.$store.commit("folders/SET_PARENT_FOLDER_DETAILS", folder);
          this.$store.commit("folders/SET_CHILD_FOLDER_DETAILS", {});
          this.$store.commit("folders/SET_NESTED_CHILD_FOLDER_DETAILS", {});
          folderName = folder?.folder?.name ?? ''
          this.setActiveFolder(folder?.folder)
        }else if (parentFolder) {
          this.$store.commit("folders/SET_PARENT_FOLDER_DETAILS", parentFolder);
          const selectedChild = parentFolder.children.find(child => child.key === id);
          if (selectedChild) {
            this.$store.commit("folders/SET_CHILD_FOLDER_DETAILS", selectedChild);
            this.$store.commit("folders/SET_NESTED_CHILD_FOLDER_DETAILS", {});
            folderName = selectedChild?.folder?.name ?? ''
            this.setActiveFolder(selectedChild?.folder)
          }
        } else if (childFolder) {
          const parentFolder = this.folderList.find(folder => {
            return folder.children && folder.children.some(child => child.key === childFolder?.key);
          });

          this.$store.commit("folders/SET_CHILD_FOLDER_DETAILS", childFolder);
          this.$store.commit("folders/SET_PARENT_FOLDER_DETAILS", parentFolder);
          const selectedNestedChild = childFolder.children.find(child => child.key === id);
          if (selectedNestedChild) {
            this.$store.commit("folders/SET_NESTED_CHILD_FOLDER_DETAILS", selectedNestedChild);
            folderName = selectedNestedChild?.folder?.name ?? ''
            this.setActiveFolder(selectedNestedChild?.folder)
          }
        }
        this.$store.dispatch("folders/fetchFolderArticles", {...payload, contentType:'article'});
        this.$store.dispatch("folders/fetchFolderMedia", {...payload, contentType:'video'});
        this.$store.dispatch("folders/fetchFolderFiles", {...payload, contentType:'podcast'});
        this.$store.dispatch('folders/fetchFolderBooks', {...payload, contentType:'book'});
        this.$store.dispatch("folders/fetchFolderSuggestedArticles", folderName);
      } catch (err) {
        console.log(err);
       }
    },
    async getFolderDiscussions(val) {
      try {
        const payload = {
          folderId: val
        }
        this.$store.dispatch('discussion/toggleDiscoverTopicCommentLoader')
        await this.$store.dispatch("folders/fetchFolderComments", payload);
        this.$store.dispatch('discussion/toggleDiscoverTopicCommentLoader')
      } catch (err) {
        console.log(err);
      }
    },
    getFolderUsersPermissions(folderId) {
      try {
        const payload = {
          folderId: folderId,
        }
        this.$store.dispatch("folders/getUsersFolderPermissions", payload);
      } catch (err) {
        console.log(err);
      }
    },
    closeSideBar(){
      this.emitter.emit('close-folder-side-bar');
    },
    serializeFolderNames(folderName){
      if(folderName === 'class_folders') {
        return folderName = 'Organization'
      }
      if(folderName === 'personal_folders') {
        return folderName = 'Private'
      }
      return folderName.split("_")[0]+""
    },
    capitalizeFirstWord(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    folderTreeTabName(){
      switch(this.activeKey){
        case 'class_folders':
          return 'Class Folders';
        case 'personal_folders':
          return 'Personal Folders'
        case 'organization_folders':
          return 'Organization Folders'
      }
    },
    openAddNewTabModal() {
      this.visibleAddNewTabModal = true;
    },
    closeAddNewTabModal() {
      this.visibleAddNewTabModal = false;
    }
  }
};
</script>
<style lang="scss">
.folder-sidebar {
  border-right: 1px solid $color-dark-grey-5;
  height: 100vh;
  position: relative;

  @include respond(tab-port) {
    height: 100%;
  }

  &__header {
    padding: 2rem 2.9rem 0;
    display: flex;
    align-items: center;

    &--folder-name {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 2.4rem;
      h3 {
        font-size: 2rem;
        font-family: $font-primary-medium;
        line-height: 2.4rem;
        color: $color-black;
        margin-bottom: 0;
      }
      .close-btn {
        display: none;
        margin-left: auto;
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        line-height: normal;
        border: none;
        outline: none;
        cursor: pointer;
        .icon {
          transform: rotate(45deg);
          display: block;
          font-size: 2.5rem;
        }
        @include respond(tab-port) {
          display: block;
        }
      }
    }
  }

  &__body {
    height: calc(100vh - 25.7rem);
    @include respond(laptop-large) {
      height: calc(100% - 25.2rem);
    }
    @include respond(laptop-medium) {
      height: calc(100% - 25.7rem);
    }
    @include respond(tab-port) {
      height: calc(100% - 18.7rem);
    }
    &--tabs {
      .ant-tabs-bar {
        border-bottom: 1px solid $color-dark-grey-5;
        margin: 0;
        margin-bottom: 2rem;
        padding: 3.1rem 2.9rem 0.1rem;
        display: flex;
        align-items: center;
        gap: 1.6rem;
        .ant-tabs-nav-container {
          padding: 0;
          .ant-tabs-tab-prev,
          .ant-tabs-tab-next {
            display: none !important;
          }

          .ant-tabs-nav-wrap {
            .ant-tabs-nav-scroll {
              overflow-x: auto;
              @include respond(phone-x-small) {
                &::-webkit-scrollbar {
                  display: none !important;
                }
              }
              &::-webkit-scrollbar {
                height: 0.5rem;
                width: 0.5rem;
              }

              &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 0.8rem;
                height: 0.5rem;
                width: 0.5rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 0.8rem;
              }

              .ant-tabs-nav {
                width: 100%;
                transform: none !important;
                @media (min-width: 991px) and (max-width: 1162px) {
                  margin-bottom: -0.4rem;
                }
                .ant-tabs-tab {
                  background-color: $color-light-grey-3;
                  padding: 1.2rem 1.6rem 1rem;;
                  border-radius: 100px;
                  margin-right: 1.6rem;
                  border-top-right-radius: 1rem !important;
                  border-top-left-radius: 1rem !important;
                  border-bottom-left-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  line-height: normal;
                  display: flex;
                  align-items: center;
                  height: 4rem;
                  .tab-name {
                    display: flex;
                    align-items: center;

                    p {
                      font-size: 1.4rem;
                      line-height: normal;
                      font-family: $font-primary-medium;
                      color: $color-dark-grey-6;
                      margin-bottom: 0;
                    }

                    svg {
                      margin-right: 1rem;
                      width: 1.5rem;
                    }

                    svg g path {
                      fill: $color-primary;
                    }
                    .dropdown {
                      margin-left: 0.5rem;
                      .icon {
                        font-size: 1.2rem;
                        color: $color-dark-grey-6;
                      }
                    }
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }

                .ant-tabs-tab-active {
                  color: $color-white;
                  background-color: $color-primary;
                  font-family: $font-primary-medium;
                  text-shadow: none;

                  .tab-name {
                    p {
                      color: $color-white;
                    }

                    svg g path,
                    svg path {
                      fill: $color-white;
                    }

                    .dropdown {
                      .icon {
                        color: $color-white;
                      }
                    }
                  }
                }

                .ant-tabs-ink-bar {
                  display: none !important;
                }
              }
              .ant-tabs-nav > div {
                display: flex;
              }
            }
          }
        }
      }

      .ant-tabs-top-content {
        .ant-tabs-tabpane {
          height: calc(100%);
          overflow: hidden;
          padding: 0;
          .meesage {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 2.3rem;
            color: $color-black;
            text-align: center;
          }
        }
      }
    }
  }
  &__footer {
    background-color: $color-white;
    border-top: 1px solid #00000033;
    padding: 1.8rem 2.9rem;
    width: 100%;
    position: relative;
    z-index: 1;
    &--btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      border: 1px solid #CDCDCD;
      border-radius: 0.8rem !important;
      background-color: transparent;
      padding: 2rem 1.7rem;
      .content {
        display: flex;
        align-items: center;
        img {
          width: 4.2rem;
          height: 4.2rem;
          margin-right: 1.2rem;
        }
        h2 {
          font-size: 1.4rem;
          font-family: $font-primary-medium;
          line-height: 1.6rem;
          color: $color-black;
          margin-bottom: 0.5rem;
          text-align: left;
        }
        p {
          font-size: 1.2rem;
          font-family: $font-primary;
          line-height: 1.4rem;
          color: #434343;
          margin-bottom: 0;
          text-align: left;
        }
      }
      .icon {
        display: block;
        width: 3rem;
        transform: rotate(270deg);
        margin-left: auto;
      }
    }
  }
}
</style>
