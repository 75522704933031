// import { computed } from "vue";

export default {
  computed:{
    folderType(){
      return this.$route.params.class
    },
    selectedFolderId(){
      return this.$route.params?.id ?? ''
    }

  },
  methods: {
    async createActivity(folderId, activityType, action) {
      try {
        const institute = await this.$store.getters["profile/institute"];
        const payload = {
          institute_id: institute.id,
          instance_id: folderId,
          actionDetails:{
             activity_type: activityType,
              action: action,
          },
        };
        if (this.institute && this.folderType === 'institute')
          await this.$store.dispatch("folders/createFolderActivity", payload);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
