<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="55rem"
    class="upload-document-modal"
  >
    <div class="upload-document-modal-container">
      <div class="upload-document-modal-container__header">
        <h1>Upload</h1>
      </div>
      <div class="upload-document-modal-container__body">
        <template v-if="modalTitle ==='Document'">
          <a-tabs v-model:activeKey="activeKey" class="upload-document-modal-container__body--tabs">
            <a-tab-pane key="document">
              <template #tab>
                <div class="tab-name">
                  <p>Document</p>
                </div>
              </template>
              <a-upload-dragger
                :beforeUpload="()=>{return false}"
                v-model:fileList="fileList"
                name="file"
                type="file"
                :accept="'.pdf'"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              >
                <div class="upload-btn">
                  <div class="upload-btn__icon">
                    <img src="@/assets/icons/add_photo.png" />
                  </div>
                  <h4 class="upload-btn__title">Click or drag file to this area to upload</h4>
                </div>
              <!-- <p class="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p> -->
            </a-upload-dragger>
            <p class="error-msg">{{ error }}</p>
            </a-tab-pane>
            <a-tab-pane key="documentLink">
              <template #tab>
                <div class="tab-name">
                  <p>Link</p>
                </div>
              </template>
              <div class="upload-document-modal-container__body--media">
                <p class="title">Enter Title</p>
                <input type="text" name="title" v-model="title" placeholder="Enter title" />
                <p class="error-msg">{{ titleError }}</p>
                <p class="title">Enter Link</p>
                <input type="url" name="link" v-model="link" placeholder="Enter Link">
                <p class="error-msg">{{ error }}</p>
              </div>
            </a-tab-pane>
          </a-tabs>
        </template>
        <template v-else>
          <div>
            <div class="upload-document-modal-container__body--media" v-if="modalTitle === 'Link' || modalTitle === 'Podcast'">
              <p class="title">Enter Title</p>
              <input type="text" name="title" v-model="title" placeholder="Enter title" />
              <p class="error-msg">{{ titleError }}</p>
              <p class="title">Enter Link</p>
              <input type="url" name="link" v-model="link" placeholder="www.yotube.com/id=123456">
              <p class="error-msg">{{ error }}</p>
            </div>
            <a-upload-dragger
              :beforeUpload="()=>{return false}"
              v-else-if="modalTitle === 'Document'"
              v-model:fileList="fileList"
              name="file"
              type="file"
              :accept="'.pdf'"
            >
              <div class="upload-btn">
                <div class="upload-btn__icon">
                  <img src="@/assets/icons/add_photo.png" />
                </div>
                <h4 class="upload-btn__title">Click or drag file to this area to upload</h4>
              </div>
            </a-upload-dragger>
            <div class="upload-document-modal-container__body--media" v-else-if="modalTitle === 'Book'">
              <p class="title">Enter Book Title</p>
              <input type="text" name="title" v-model="title" placeholder="Enter title" />
              <p class="error-msg">{{ titleError }}</p>
              <p class="title">Enter Book Link</p>
              <input type="url" name="link" v-model="link" placeholder="Enter book link">
              <p class="error-msg">{{ error }}</p>
              <p class="title">Image</p>
              <a-upload-dragger
                v-model:fileList="fileList"
                list-type="picture-card"
                :beforeUpload="()=>{return false}"
                name="file"
                type="file"
                :max-count="1"
                :accept='[
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "image/webp",
                  "image/svg+xml",
                  "image/gif",
                  "image/bmp",
                ]'
              >
                <div class="upload-btn">
                  <div class="upload-btn__icon">
                    <img src="@/assets/icons/add_photo.png" />
                  </div>
                  <h4 class="upload-btn__title">Click or drag image to this area to upload</h4>
                </div>
              </a-upload-dragger>
            </div>
          </div>
        </template>
        <a-button :loading="isLoading" @click="isArchiveContentModal ? uploadArchiveFolderContent() : uploadFolderContent()" class="upload-document-modal-container__body--btn">
          Upload
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    visible: Boolean,
    folderId: String,
    modalTitle: String,
    isArchiveContentModal:{
      type:Boolean,
      default: ()=>(false)
    }
  },
  data() {
    return {
      fileList: [],
      isLoading: false,
      link: "",
      title: "",
      titleError: null,
      error: null,
      validLinkTypes: [
        'youtube.com',
        'vimeo.com',
        'facebook.com',
        'instagram',
        'tiktok.com',
        'twitter.com',
        'podbean.com',
        'apple.com',
        'Castos.com',
        'Buzzsprout.com',
        'libsyn.com'
      ],
      activeKey:'document'
    };
  },
  watch: {
    visible(val){
      if (val) {
        this.titleError = null;
      }
    },
    activeKey(val){
      this.error = ''
    }
  },
  emits: ["close-modal"],
  computed: {
    ...mapGetters({
      selectedArchiveFolder: "archive/selectedArchiveFolder",
    }),
    contentList:{
      get(){
        switch(this.contentType){
            case 'video':
              return this.$store.getters["folders/getFolderMediaContent"]
            
            case 'podcast':
              return this.$store.getters["folders/getFolderPodcastContent"]
          
            case 'book':
              return this.$store.getters["folders/getFolderBooksContent"]
          
            case 'document':
              return this.$store.getters["folders/getFolderArticleContent"]

            default:
              return []
          }
     },
     set(content){
        switch(this.contentType){
            case 'video':
              return this.$store.commit("folders/SET_FOLDER_MEDIA_CONTENT", content)
            
            case 'podcast':
              return this.$store.commit("folders/SET_FOLDER_PODCASTS_CONTENT", content)
          
            case 'book':
              return this.$store.commit("folders/SET_FOLDER_BOOKS_CONTENT", content)
          
            case 'document':
              return this.$store.commit("folders/SET_FOLDER_ARTICLE_CONTENT", content)

            default:
              return []
        }
     }
        
    },
    contentType(){
      if (this.modalTitle === 'Link')
        return 'video'
      else if (this.modalTitle === 'Podcast')
        return 'podcast'
      else if (this.modalTitle === 'Book')
        return 'book'
      else 
        return 'document'
    }
  },
  methods: {
    ...mapActions({
      saveArticleToArchiveFolder: "archive/saveArticleToArchiveFolder",
    }),
    closeModal() {
      this.$emit("close-modal");
      this.fileList = [];
      this.title = "";
      this.link = "";
      this.error = null;
    },
    async uploadFolderContent() {
      try {
        this.error = "";
        if (!this.validateInputs()) return;

        this.isLoading = true;

        const payload = {
          folder_id: this.folderId,
          content: this.prepareForm(),
        };

        const response = await this.$store.dispatch("folders/saveContentInFolder", payload);

        if (response.status === 201) {
          this.handleSuccess(response.data);
          this.$showToast({message:'Content uploaded successfully.', type:'success'})
        }

        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },

    async uploadArchiveFolderContent(){
      try{
        this.error = "";
        if (!this.validateInputs()) return;

        this.isLoading = true;
        // const payload = {
        //   folderId: this.selectedArchiveFolder.id,
        //   article_details:{
        //     content_type: this.contentType ? 'videos' : 'podcast',
        //     link: this.link,
        //     title: this.title
        //   }
        // };
        const form = new FormData()
        if (this.contentType !== 'documents' && this.contentType !== 'books'){
          form.append('content_type', this.contentType)
          form.append('link', this.link)
          form.append('title', this.title)
        }
        else if (this.contentType === 'books'){
          if (this.fileList.length > 0){
            this.fileList.forEach((val) => {
              form.append("title", this.title);
              form.append("thumbnail", val.originFileObj);
              form.append("content_type",  this.contentType);
              form.append("link",  this.link);
            });
          }
          else{
              form.append("title", this.title);
              form.append("content_type",  this.contentType);
              form.append("link",  this.link);
          }
        }
        else{
          if (this.fileList) {
            this.fileList.forEach((val) => {
              this.title = val.originFileObj.name;
              form.append("title", this.title);
              form.append("file", val.originFileObj);
              form.append("content_type",  this.contentType);
            });
            form.append("title", this.title);
            form.append("content_type",  this.contentType);
            form.append("link",  this.link);
          }

        }
        const payload = {
          folderId: this.folderId,
          article_details:form
        };


        await this.saveArticleToArchiveFolder(payload)
        this.isLoading = false;
        this.closeModal();
        this.title = "";
        this.link = "";
        this.$showToast({message:'Content uploaded successfully.', type:'success'})

      }
      catch(err){
        this.isLoading = false;
        this.closeModal();
        this.title = "";
        this.link = "";
      }
    },

    validateInputs() {
      if (this.modalTitle === 'Link' && this.title === "" || this.modalTitle === 'Podcast' && this.title === "" || this.modalTitle === 'Book' && this.title === "" || this.modalTitle === 'Document' && this.title === "" && this.activeKey === 'documentLink' ) {
        this.titleError = "This field is required";
        this.error = "";
        return false;
      }

      if (this.modalTitle === 'Link' && this.link === "" || this.modalTitle === 'Podcast' && this.link === "" || this.modalTitle === 'Book' && this.link === "" || this.modalTitle === 'Document' && this.link === "" && this.activeKey === 'documentLink' ) {
        this.error = "This field is required";
        this.titleError = "";
        return false;
      }
      if ((this.modalTitle === 'Link' || this.modalTitle === 'Podcast') && !this.isValidLink()) {
        this.error = "Invalid link only 'Youtube' , 'Vimeo' & Social media links are allowed!";
        return false;
      }

      if (this.modalTitle === 'Document' && this.fileList.length === 0 && this.activeKey !== 'documentLink'){
        this.error = "Please upload a document";
        return false;
      }

      return true;
    },
    isValidLink() {
      return this.validLinkTypes.some(type => this.link.includes(type));
    },

    prepareForm() {

      const form  =  new FormData()
      let contentTitle = this.title
      
      form.append("title", contentTitle);
      form.append("link_content", this.link);
      form.append("content", this.contentType);

      if (this.contentType === 'book' || (this.contentType === 'document' &&  this.activeKey !== 'documentLink') ){
          const key = this.contentType === 'book' ? "thumbnail" : "file_content"
          this.fileList.forEach((val) => {
            contentTitle = val.originFileObj.name
            form.append(key, val.originFileObj);
          });
          // remove link_content and update the title to the file name if pdf is being uploaded
          if (this.contentType === 'document' &&  this.activeKey !== 'documentLink'){
            form.delete('link_content')
            form.set("title", contentTitle);
          }
      }

      return form
    },

    handleSuccess(data) {
      this.error = null;
      this.contentList.push(data)
      this.title = "";
      this.link = "";
      this.fileList = [];
      this.closeModal();
    }
  },
};
</script>

<style lang="scss">
.upload-document-modal {
  top: 10rem !important;
  @include respond(laptop-small) {
    top: 8rem !important;
  }
  .ant-modal-content {
    background-color: $color-white;

    .ant-modal-close {
      margin: 1.9rem 2rem 0 0 !important;
    }
    .ant-modal-body {
      padding: 0;
      .upload-document-modal-container {
        padding: 2.2rem 0;
        &__header {
          margin-bottom: 2rem;
          padding: 0 2.5rem 2rem;
          border-bottom: 1px solid rgba(135, 149, 157, 0.3);
          h1 {
            font-size: 2rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: 2.3rem;
            color: $color-black;
          }
        }
        &__body {
          padding: 0 2.5rem;
          .ant-upload {
            background-color: transparent;
            border: 1px dashed #bdbdbd;
            border-radius: 0.5rem;
            &:hover {
              border-color: $color-primary;
            }
            .ant-upload-btn {
              padding: 3rem 2rem;
              .ant-upload-drag-container {  
                .upload-btn {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  background-color: $color-white;
                  padding: 1rem;
                  &__icon {
                    background-color: rgb(228, 230, 235);
                    height: 4.5rem;
                    width: 4.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    margin-bottom: 1rem;
                    img {
                      width: 2rem;
                      opacity: 0.4;
                    }
                  }
                  &__title {
                    font-size: 1.6rem;
                    font-family: $font-primary;
                    line-height: normal;
                    margin-bottom: 0;
                    color: $color-black;
                    opacity: 0.5;
                  }
                }
                .ant-upload-hint {
                  font-size: 1.6rem;
                  font-family: $font-primary;
                  color: $color-black;
                  line-height: 2rem;
                  opacity: 0.3;
                }
              }
            }
          }
          .ant-upload-list {
            margin: 1rem 0;
            display: flex;
            height: max-content;
            .ant-upload-list-picture-card-container {
              height: 9rem;
              width: 9rem;
              margin: 0 0.8rem 0.8rem 0;
            }
            .ant-upload-list-item {
              margin: 0 0.8rem 0.8rem 0;
              height: 9rem;
              width: 9rem;
              padding: 1rem;
              border: 1px solid #c5c6cc;
              border-radius: 0.2rem;
              .ant-upload-list-item-info {
                padding: 0;
                background-color: $color-white;
                position: relative;
                &:hover {
                  background-color: $color-white;
                }
                .anticon-paper-clip {
                  top: 50%;
                  transform: translateY(-50%);
                  font-size: 1.4rem;
                  color: $color-black;
                }
                .ant-upload-list-item-name {
                  padding-left: 2.2rem;
                  font-size: 1.4rem;
                  line-height: 2rem;
                  color: $color-black;
                }
              }
              .ant-upload-list-item-actions {
                a {
                  cursor: pointer;
                  .anticon {
                    padding: 0;
                  }
                }
                .anticon {
                  width: 1.8rem;
                  margin: 0 0.4rem;
                  svg {
                    width: 100%;
                  }
                }
              }
            }
            .ant-upload-list-item-error {
              .anticon-paper-clip,
              .ant-upload-list-item-name,
              .anticon {
                color: $color-black !important;
              }
            }
          }
          &--media {
             .title {
              font-size: 1.4rem;
              font-family: $font-primary;
              font-weight: 500;
              margin-bottom: 1rem;
              line-height: 1.6rem;
              width: fit-content;
              color: $color-black;
            }
            input {
              border-radius: 5px !important;
              font-size: 1.4rem;
              border: 1px solid #c5c6cc;
              font-family: $font-primary;
              width: 100%;
              height: 4rem;
              padding: 1rem;
              margin-bottom: 1rem;
              outline: none;
              &::placeholder {
                opacity: 0.4;
                color: $color-black;
              }
            }
          }
          .error-msg {
            margin: -0.5rem 0 1rem 0;
            font-size: 1.4rem;
            font-family: $font-secondary;
            color: red;
            line-height: 1.8rem;
          }
          &--btn {
            outline: none;
            border: none;
            background-color: $color-primary;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            color: $color-white;
            height: 4rem;
            width: 10rem;
            display: block;
            margin-left: auto;
            border-radius: 5px !important;
            cursor: pointer;
            margin-top: 3rem;
          }
          &--tabs {
            .ant-tabs-bar {
              margin: 0;
              border: none;
              .ant-tabs-nav-container {
                .ant-tabs-tab-prev,
                .ant-tabs-tab-next {
                  display: none !important;
                }
                .ant-tabs-nav-wrap {
                  .ant-tabs-nav-scroll {
                    overflow: hidden;
                    margin-bottom: 2rem;
                    .ant-tabs-nav {
                      width: 100%;
                      transform: none !important;
                      .ant-tabs-tab {
                        padding: 0.9rem 2rem;
                        border-radius: 100px;
                        border: 1px solid $color-dark-grey-5;
                        margin-right: 1.2rem;
                        margin-bottom: 0.2rem;
                        .tab-name {
                          display: flex;
                          align-items: center;
                          p {
                            font-size: 1.4rem;
                            line-height: normal;
                            font-family: $font-primary;
                            color: $color-dark-grey-6;
                            margin: 0;
                          }
                        }

                        &:last-child {
                          margin-right: 0;
                        }
                      }

                      .ant-tabs-tab-active {
                        background-color: $color-dark-grey-5;
                        text-shadow: none;
                        border-color: $color-dark-grey-5;
                        .tab-name {
                          p {
                            color: $color-black;
                            font-family: $font-primary-medium;
                          }
                        }
                      }
                      .ant-tabs-ink-bar {
                        display: none !important;
                      }
                    }
                    .ant-tabs-nav > div {
                      display: flex;
                    }
                  }
                }
              }
            }
            .ant-tabs-top-content {
              .ant-tabs-tabpane {
                height: max-content;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
  .error-msg {
    margin: -0.5rem 0 1rem 0;
    font-size: 1.6rem;
    font-family: $font-secondary;
    color: red;
  }
}
</style>
